import { ReactElement } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import css from './Layout.module.css'

type LayoutProps = {
  children: ReactElement
}

const Layout = ({ children }: LayoutProps): ReactElement<LayoutProps> => {
  return (
    <div className={css.Layout} id='top-layout'>
      <Header></Header>

      <div className={css.main}>{children}</div>

      <Footer></Footer>
    </div>
  )
}

export default Layout
