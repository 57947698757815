import { ReactElement } from 'react'
// import styled from 'styled-components'
import AuraLogo from '../../assets/icons/aura-logo-white.svg'
import css from './Footer.module.css'

// const LinkContain = styled.div `
//   display: flex;
//   flex-direction:column;
//   margin: 20px
// `

// const Link = styled.a`
//   color: #868A97;
//   font-size: 12px;
//   margin: 5px;
// `

const Footer = (): ReactElement => {
  return (
    <div className={css.Footer}>
      <div className='container'>
        <div className='flex flex-col lg:flex-row justify-between items-center py-6 lg:py-[40px] px-4 lg:px-[160px] gap-y-[24px]'>
          <div className='flex  items-center gap-3 text-white'>
            A product by
            <img
              className='cursor-pointer h-[60px]'
              onClick={() => {
                window.open('https://aura.network/')
              }}
              src={AuraLogo}
              alt=''
            />
          </div>
          {/* <LinkContain>
            <Link href='/privacy'>
              Privacy Policy
            </Link>
            <Link href='/terms'>
              Terms and Conditions
            </Link>
          </LinkContain> */}
          <div className='text-xs text-gray-c1'>Copyright © 2022 Aura Network. All rights reserved</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
