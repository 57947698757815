import { ComponentPropsWithoutRef, ReactElement } from 'react'
import css from './Button.module.css'

type ButtonProps = ComponentPropsWithoutRef<'button'>

export default function GradientButton({ children, className = '', ...props }: ButtonProps): ReactElement {
  return (
    <button className={`${css.GradientButton} ${className}`} {...props}>
      <span>{children}</span>
    </button>
  )
}
