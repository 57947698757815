import { ReactElement } from 'react'
import css from './Card.module.css'

import LinkSimpleIcon from '../../assets/icons/link-simple.svg'

type Props = {
  title: string
  description: string
  url?: string
}
export default function LinkCard({ title, description, url }: Props): ReactElement {
  return (
    <div
      className={css.LinkCard}
      onClick={() => {
        url && window.open(url)
      }}
    >
      <div className='flex justify-between items-start'>
        <p className='text-16px text-white font-normal capitalize grow-0'> {title}</p>
        <img src={LinkSimpleIcon} alt='' className='w-[20px] shrink-0' />
      </div>
      <p className='text-14px text-gray-c1 font-semibold'>{description}</p>
    </div>
  )
}
