import { ReactElement, useCallback } from 'react'
import PyxisSafeLogo from '../../assets/icons/pyxis-safe.svg'
import css from './Header.module.css'

import { PYXIS_APP_URL } from '../../constant/common'
import { GradientButton } from '../Button'
import { HeaderText } from '../Text'
const Header = (): ReactElement => {
  const scrollTo = useCallback((id: string) => {
    const titleElement = document.getElementById(id)
    if (titleElement) {
      titleElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])


  return (
    <header className={css.Header}>
      <div className={css.Divider}>
        <a href='/'>
        <img
          className='cursor-pointer h-[48px] lg:h-fit'
          src={PyxisSafeLogo}
          alt=''
        />
        </a> 
      </div>
      <div className='hidden lg:flex justify-end items-center gap-[40px]'>
        <ul className='flex items-center gap-[40px]'>
          <li>
            <HeaderText onClick={() => scrollTo('features')}>Features</HeaderText>
          </li>
          <li>
            <HeaderText onClick={() => scrollTo('comparison')}>Comparison</HeaderText>
          </li>
          <li>
            <HeaderText onClick={() => scrollTo('getting-started')}>Getting Started</HeaderText>
          </li>
        </ul>

        <GradientButton
          onClick={() => {
            window.open(PYXIS_APP_URL)
          }}
        >
          OPEN APP
        </GradientButton>
      </div>

      {/* <div className='lg:hidden relative'>
        <img className='cursor-pointer h-[32px]' src={LineLogo} alt='' onClick={() => setShowMenu(true)} />
        {showMenu && (
          <div className={css.MenuMobile}>
            <div className='flex justify-between items-center h-12'>
              <img className='cursor-pointer h-[48px] lg:h-fit' onClick={handleShowMenu} src={PyxisSafeLogo} alt='' />

              <img className='cursor-pointer h-[32px]' src={LineLogo} alt='' onClick={() => setShowMenu(false)} />
            </div>

            <div className='flex flex-col justify-center items-center gap-[40px] pt-9'>
              <ul className='flex flex-col items-center gap-[40px]'>
                <li>
                  <HeaderText onClick={() => scrollTo('features')}>FEATURES</HeaderText>
                </li>
                <li>
                  <HeaderText onClick={() => scrollTo('comparison')}>COMPARISON</HeaderText>
                </li>
                <li>
                  <HeaderText onClick={() => scrollTo('getting-started')}>GETTING STARTED</HeaderText>
                </li>
              </ul>

              <GradientButton
                onClick={() => {
                  window.open('https://safe.serenity.aura.network')
                }}
              >
                OPEN APP
              </GradientButton>
            </div>
          </div>
        )}
      </div> */}
    </header>
  )
}

export default Header
