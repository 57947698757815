import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
function App() {
  return (
    <>
    <Router>
      <Layout>
        <Switch>
          <Route path="/" component={Home} />
          {/* <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} /> */}
        </Switch>
      </Layout>
    </Router>
    </>
  )
}

export default App
