import { ReactElement } from 'react'

import GithubIcon from '../../assets/icons/github.svg'
import Planet from '../../assets/icons/Planet.png'
import Solar from '../../assets/icons/Solar-System.png'
import Stars from '../../assets/icons/Stars.png'
import AuraLogo from '../../assets/images/aura-network.png'
import BankVault from '../../assets/images/bank_vault.png'
import CantoLogo from '../../assets/images/canto-logo.png'
import CosmosLogo from '../../assets/images/cosmos-logo.png'
import EvmosLogo from '../../assets/images/Evmos_PrimaryLogo_Sand.png'
import Galaxy from '../../assets/images/galaxy.png'
import LostAstronaut from '../../assets/images/Lost-Astronaut.png'

import { GradientButton } from '../../components/Button'
import { Card, LinkCard } from '../../components/Card'
import { ComparisonTable } from '../../components/Table'
import { GradientText } from '../../components/Text'
import { PYXIS_APP_URL } from '../../constant/common'
import css from './Home.module.css'

export default function Home(): ReactElement {
  const ViewGitHub = () => (
    <div className='order-3'>
      <span className='text-white lg:font-medium'>
        Visit our{' '}
        <a
          onClick={() => {
            window.open('https://github.com/aura-nw/aura-safe')
          }}
          className='underline cursor-pointer'
        >
          Github
        </a>
        <img src={GithubIcon} alt='' className='w-[32px] ml-2 inline' />
      </span>
    </div>
  )

  return (
    <>
        <div className={css.Home}>
          <section className={css.section + ' flex flex-col lg:flex-row items-center justify-center gap-6 lg:gap-8'}>
            <div className='h-full w-9/12 lg:w-7/12 flex justify-end'>
              <img className='w-full' src={BankVault} alt='' />
            </div>
            <div className='w-full lg:w-5/12 flex flex-col items-center lg:items-start justify-start gap-6 lg:gap-10'>
              <GradientText className='g-text-h2'>The Multi-signature safe of the Interchain</GradientText>
              <div className='w-[128px]'>
                <GradientButton
                  onClick={() => {
                    window.open(PYXIS_APP_URL)
                  }}
                >
                  OPEN APP
                </GradientButton>
              </div>
            </div>
          </section>

          <section id='features' className={css.section + ' flex flex-col items-center justify-center gap-6 lg:gap-8'}>
            <GradientText className='g-text-h3 text-center'> Features </GradientText>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8'>
              <>
                <Card
                  key={0}
                  image={Stars}
                  title='Costless Multisignature'
                  description='Creating a Safe is totally free of charge'
                ></Card>
              </>

              <>
                <Card
                  key={1}
                  image={Solar}
                  title='Interchain Assets'
                  description='Support storing and transferring assets for various chains based on Cosmos SDK'
                ></Card>
              </>

              <>
                <Card
                  key={2}
                  image={Planet}
                  title='Non-custodial'
                  description='Stored assets truly belong to owners without any smart contract implementation'
                ></Card>
              </>
            </div>
          </section>

          <section
            id='available'
            className={css.section + ' flex flex-col items-center justify-center gap-6 lg:gap-12'}
          >
            <GradientText className='g-text-h3 text-center'> Available on </GradientText>
            <div className='grid grid-cols-2 gap-4 lg:grid-cols-4 justify-between w-full'>
              <div className='flex justify-center'>
                <img className='h-8 lg:h-16' src={AuraLogo} alt='Aura' />
              </div>
              <div className='flex justify-center'>
                <img className='h-8 lg:h-16' src={EvmosLogo} alt='Evmos' />
              </div>
              <div className='flex justify-center'>
                <img className='h-8 lg:h-16' src={CosmosLogo} alt='Cosmos' />
              </div>
              <div className='flex justify-center'>
                <img className='h-8 lg:h-16' src={CantoLogo} alt='Canto' />
              </div>
            </div>
          </section>

          <section
            id='comparison'
            className={css.section + ' flex flex-col items-center justify-center gap-6 lg:gap-12'}
          >
            <GradientText className='g-text-h3 text-center'> Comparison </GradientText>
            <div className='w-full'>
              <ComparisonTable></ComparisonTable>
            </div>
          </section>

          <section id='getting-started' className={css.section}>
            <div className='flex flex-col lg:flex-row gap-8 lg:gap-12 justify-between pb-4 lg:pb-[50px]'>
              <div className='flex flex-col lg:w-1/2 gap-6 lg:gap-8'>
                <GradientText className='text-center lg:text-start g-text-h3'> Getting started </GradientText>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8'>
                  <LinkCard
                    url='https://docs.aura.network/product/pyxis-safe/getting-started/connect-your-wallet'
                    title='Overview'
                    description='Wallet connection and network selection'
                  ></LinkCard>
                  <LinkCard
                    url='https://docs.aura.network/product/pyxis-safe/getting-started/create-a-new-safe'
                    title='Knowing your Safe'
                    description='Creating and navigating through your Safes'
                  ></LinkCard>
                  <LinkCard
                    url='https://docs.aura.network/product/pyxis-safe/getting-started/create-safe-transaction'
                    title='Making transaction'
                    description='Create, approve/ reject, and execute transactions'
                  ></LinkCard>
                </div>
                <div className='hidden lg:block'> {ViewGitHub()} </div>
              </div>
              <div className='lg:w-4/12 flex justify-end lg:block'>
                <img src={Galaxy} alt='' className='w-7/12 lg:hidden h-fit' />
                <img
                  src={LostAstronaut}
                  alt=''
                  className={`${css.LostAstronaut} + ' w-7/12 lg:w-full translate-y-4'`}
                />
              </div>
              <div className='lg:hidden text-center'> {ViewGitHub()} </div>
            </div>
          </section>
        </div>
    </>
  )
}
