import { ComponentPropsWithoutRef, ReactElement } from 'react'

import css from './Text.module.css'

type Props = ComponentPropsWithoutRef<'h1'>

export default function GradientText({ children, className, ...props }: Props): ReactElement<Props> {
  return (
    <h1 className={`${css.gradient} ${className}`} {...props}>
      {children}
    </h1>
  )
}
