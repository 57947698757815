import { ReactElement } from 'react'
import css from './Card.module.css'
type Props = {
  image: string
  title: string
  description: string
}
export default function Card({ image, title, description }: Props): ReactElement {
  return (
    <div className={css.Card}>
      <div className='min-w-[108px]  lg:min-w-[134px]'>
        <img className='w-[108px] lg:w-[134px]' src={image} alt='' />
      </div>
      <div className='flex flex-col  gap-6'>
        <p className='font-medium lg:text-2xl text-white capitalize'> {title}</p>
        <p className='lg:text-2xl text-white opacity-70'>{description}</p>
      </div>
    </div>
  )
}
