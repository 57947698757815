import { ReactElement } from 'react'

import css from './Table.module.css'

import CheckIcon from '../../assets/icons/check.svg'
import CheckedIcon from '../../assets/icons/checked.svg'
import NonIcon from '../../assets/icons/minus.svg'
import UnCheckedIcon from '../../assets/icons/unchecked.svg'

enum CompareValue {
  Checked,
  Check,
  UnChecked,
  Non,
}

type Props = {
  [key: string]: {
    auraSafe: CompareValue
    softwareWallet: CompareValue
    hardwareWallet: CompareValue
    cexExchange: CompareValue
  }
}

const DEFAULT: Props = {
  Multisignature: {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.UnChecked,
    hardwareWallet: CompareValue.UnChecked,
    cexExchange: CompareValue.UnChecked,
  },
  'Costless Safe': {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.Non,
    hardwareWallet: CompareValue.Non,
    cexExchange: CompareValue.Non,
  },
  'Defi Intergration': {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.Check,
    hardwareWallet: CompareValue.UnChecked,
    cexExchange: CompareValue.UnChecked,
  },
  Privacy: {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.Check,
    hardwareWallet: CompareValue.Checked,
    cexExchange: CompareValue.UnChecked,
  },
  'Gasless signature': {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.Non,
    cexExchange: CompareValue.Non,
    hardwareWallet: CompareValue.Non,
  },
  'Collectibles (NFTs)': {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.Check,
    hardwareWallet: CompareValue.UnChecked,
    cexExchange: CompareValue.UnChecked,
  },
  'Open sources': {
    auraSafe: CompareValue.Checked,
    softwareWallet: CompareValue.Check,
    hardwareWallet: CompareValue.UnChecked,
    cexExchange: CompareValue.UnChecked,
  },
}

export const StatusIcon = ({ statusIcon }: { statusIcon: string }) => {
  return <img className='w-[16px] lg:w-[24px]' src={statusIcon}></img>
}
export default function ComparisonTable(): ReactElement {
  const status = (status: CompareValue) => {
    switch (status) {
      case CompareValue.Checked:
        return <StatusIcon statusIcon={CheckedIcon}></StatusIcon>
      case CompareValue.UnChecked:
        return <StatusIcon statusIcon={UnCheckedIcon}></StatusIcon>
      case CompareValue.Check:
        return <StatusIcon statusIcon={CheckIcon}></StatusIcon>
      default:
        return <StatusIcon statusIcon={NonIcon}></StatusIcon>
    }
  }

  return (
    <div className='w-full flex flex-col'>
      <div className={css.ComparisonTableRow}>
        <> &nbsp; </>
        <div className='flex-justify-center'>
          <p className={css.Title}>Pyxis Safe</p>
        </div>
        <div className='flex-justify-center'>
          <p className={css.Title}>Software Wallet</p>
        </div>
        <div className='flex-justify-center'>
          <p className={css.Title}>Hardware Wallet</p>
        </div>
        <div className='flex-justify-center'>
          <p className={css.Title}>Centralized Exchange</p>
        </div>
      </div>
      {Object.keys(DEFAULT).map((key) => (
        <div className={css.ComparisonTableRow} key={key}>
          <div className='flex items-center lg:px-4'>
            <p className='text-xs lg:text-16px text-white capitalize whitespace-nowrap'>{key}</p>
          </div>
          <div className='flex-center-center'>{status(DEFAULT[key].auraSafe)}</div>
          <div className='flex-center-center'>{status(DEFAULT[key].softwareWallet)}</div>
          <div className='flex-center-center'>{status(DEFAULT[key].hardwareWallet)}</div>
          <div className='flex-center-center'>{status(DEFAULT[key].cexExchange)}</div>
        </div>
      ))}
    </div>
  )
}
