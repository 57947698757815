import React, { ReactElement } from 'react'
import css from './Text.module.css'

type Props = {
  children: string
  href?: string
  onClick: () => void
}

export default function HeaderText({ children, onClick }: Props): ReactElement<Props> {
  return (
    <a onClick={onClick} className={css.textHeader}>
      {children}
    </a>
  )
}
